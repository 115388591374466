import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import "./Styles.css";
import Sticky from "@ui/sticky";

const Educator = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);


    return (

        <Layout pageTitle="" className="white-version">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />

        <div>
            <div>
                <main className="main-page-wrapper">
                    <div className="rn-slider-area">
                        <div className="container">
                            <div className="row row--30 pt--100 pt_sm--50">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-wrap align-content-start h-100">
                                        <Sticky
                                            className="sticky-top-slider"
                                            top="200px"
                                        >
                                            <div className="banner-details-wrapper-sticky slide">
                                                <div className="thumbnail">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="banner-title-area pt--30">
                                                    <h1
                                                        className="title"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        Hi, I’m{" "}
                                                        <span>Anuj</span>
                                                        <br />
                                                        {/*<span className="span" style={{ color: '#000' }}> Software Engineer.</span>*/}
                                                    </h1>
                                                    <p className="disc">
                                                        Hailing from a family of
                                                        educators, I’ve always
                                                        been passionate about
                                                        coaching people on
                                                        public speaking and the
                                                        art of communication. I
                                                        head operations and
                                                        communications for
                                                        Sherwood Public School
                                                        in Hyderabad.
                                                    </p>
                                                </div>
                                            </div>
                                        </Sticky>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Educator
                                                </h5>
                                                <p className="about-disc">
                                                    I’m proudly from a family of
                                                    educators. My parents run a
                                                    school, Sherwood Public
                                                    School, in Hyderabad.
                                                    Working in the education
                                                    sector made me realise that
                                                    being able to speak in front
                                                    of people or an audience is
                                                    an essential skill that most
                                                    people lack. A lot of people
                                                    get nervous at the thought
                                                    of the public.
                                                </p>
                                                <p className="about-disc">
                                                    Having been a public speaker
                                                    and event host for over two
                                                    decades, I have mastered the
                                                    art of communication and
                                                    public speaking. Using
                                                    specific techniques and
                                                    role-playing activities, I
                                                    help people develop the
                                                    knowledge, skills, and
                                                    confidence required to
                                                    effectively communicate with
                                                    an audience.
                                                </p>
                                                <p className="about-disc">
                                                    I coach people on how to
                                                    communicate better in public
                                                    gatherings, overcome the
                                                    fear of public speaking, and
                                                    display the right body
                                                    language when speaking. With
                                                    my proven communication
                                                    process, you can perfect
                                                    your speech and
                                                    communication skills.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Contact Me
                                                </h5>

                                                <p className="about-disc">
                                                    Let’s connect on your
                                                    project, either social or
                                                    corporate. I’m available for
                                                    freelance work as a master
                                                    of ceremony, playback
                                                    singer, actor, voice artist
                                                    or educator.  
                                                </p>
                                                <p>
                                                    Email: anujgurwara@gmail.com 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};




export const query = graphql`
    query Educator {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1-white" }) {
            ...Footer01
        }
        homePage(title: { eq: "politician-home-white" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

Educator.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
     
        
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default Educator;
